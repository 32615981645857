
import { Options, Vue } from "vue-property-decorator";
import api from "@/api/mynotice";
@Options({
  name: "Noticedetail",
})
export default class extends Vue {
  reqdata = {};
  popupShow = false;
  imgFile = "";
  mounted() {
    api.mobilemsggetById(this.$route.params.id).then((res: any) => {
      console.log("llll", res);
      if (res.code !== 0) {
        return this.$toast.fail(res.msg);
      }
      this.reqdata = res.data;
    });
    api.mobilemsgreadById(this.$route.params.id).then((res) => {
      console.log("1");
    });
  }
  typeFilter(item: any) {
    let imgType = ["png", "gif", "jpg", "jpeg"];
  }
  async fDelete() {
    const confirmResult = await this.$dialog
      .confirm({
        title: "标题",
        message: "此操作将永久删除该文件，是否继续？",
      })
      .catch((err) => err);
    if (confirmResult === "confirm") {
      const res: any = await api.mobilemsgdeleteById(this.$route.params.id);
      if (res.code !== 0) {
        return this.$toast.fail(res.msg);
      }
      this.$toast.success({
        message: res.msg,
        onClose: () => {
          this.$router.go(-1);
        },
      });
    }
  }
  fShowFile(param: any) {
    this.popupShow = true;
    this.imgFile = param.url;
  }
}
